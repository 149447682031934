import React, {useState} from 'react';
import css from './SocialShare.module.css';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

function SocialShare(props) {
    const {currentUrl} = props;
    const [copied, setCopied] = useState(false);
    const title = 'Checkout this listing on Inseminexx!';

    const truncatedUrl = currentUrl.length > 30 ? `${currentUrl.substring(0, 30)}...` : currentUrl;
  
    const handleCopyClick = () => {
      navigator.clipboard.writeText(currentUrl)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); 
        })
        .catch(error => console.error('Error copying URL: ', error));
    };

    const handleEmailShare = () => {
      const subject = 'Check out this listing';
      const body = `Hey, I thought you might be interested in this listing: ${currentUrl}`;
      const emailUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.open(emailUrl,'MyWindow','width=600,height=600')
    };
    
  
  return (
    <>
    <div>
      <hr />
      <p className={css.socialtitle}>Share this listing</p>
      <div>
      <div className={css.socialcontainer}>
        <div>
          <button onClick={handleEmailShare} className={css.emailbutton}>
            <EmailIcon size={32} round />
          </button>
        </div>
        <div>
          <FacebookShareButton url={currentUrl} title={title} className={css.sharebutton}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        </div>
        <div>
          <TwitterShareButton url={currentUrl} title={title} className={css.sharebutton}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </div>
        <div>
          <LinkedinShareButton url={currentUrl} title={title} className={css.sharebutton}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>
        <div>
          <WhatsappShareButton url={currentUrl} title={title} className={css.sharebutton}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </div>
      </div>
          <button className={css.copybutton} onClick={handleCopyClick}>
          {truncatedUrl} <span className={css.copy}>Copy</span> 
          </button>
          {copied && <span className={css.copied}>Copied!</span>}
        </div>
      </div>
    </>
  );
}

export default SocialShare;
